import { get, post } from '@/api/index'
``

// 获取个人任务列表
export const getPersonTaskList = params => get(`/dapi/task-core/v1/task/personal/list`, params)

// 获取任务列表
export const getTaskList = params => {
  let urlStr = '?'
  if ('creatorIds' in params && params.creatorIds.length > 0) {
    urlStr = urlStr + params.creatorIds.map(k => `creatorIds=${k}`).join('&')
  }
  if ('executorIds' in params && params.executorIds.length > 0) {
    urlStr = urlStr + '&' + params.executorIds.map(k => `executorIds=${k}`).join('&')
  }
  delete params.executorIds
  delete params.creatorIds
  return get(`/dapi/task-core/v1/task/list${urlStr}`, params)
}

// 添加任务
export const addTask = params => post(`/dapi/task-core/v1/task/management/add`, params)

// 获取任务详情
export const getTaskDetail = params => get(`/dapi/task-core/v1/task/management/detail`, params)

// 修改任务
export const updateTask = params => post(`/dapi/task-core/v1/task/management/update`, params)

// 删除任务
export const deleteTask = params => post(`/dapi/task-core/v1/task/management/delete`, params)

// 催办任务
export const urgeTask = params => post(`/dapi/task-core/v1/task/management/urge`, params)

// 完成任务
export const finishTask = params => post(`/dapi/task-core/v1/task/personal/finish`, params)

