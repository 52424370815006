<template>
  <span class="status-text"><i class="status-circle" />{{text}}</span>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>
<style scoped lang="less">
.status-circle {
  width: 0.1932rem;
  height: 0.1932rem;
  border-radius: 0.1932rem;
  display: inline-block;
  background-color: #03c77b;
  margin-right: 0.0966rem;
}
.status-text {
  font-size: 0.29rem;
  color: #333333;
  margin-right: 0.1932rem;
}
</style>
