<template>
  <div class="describe__item">
    <span class="describe__label">{{ label }}</span>
    <span v-if="content" class="describe__content" v-text="content">
    </span>
    <span v-else  class="describe__content">
      <slot />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="less">
.describe__item {
  margin-bottom: 0.121rem;
  display: flex;
  justify-content: space-between;
  line-height: 0.483rem;
}
.describe__label {
  font-size: 0.29rem;
  color: #999999;
  flex-basis: 2.03rem;
  flex-grow: 0;
  flex-shrink: 0;
}
.describe__content {
  font-size: 0.314rem;
  color: #333333;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
